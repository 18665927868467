<!-- Tree.vue -->
<template>
  <details
    :class="['treeitem', compareType, { modify: data.state === 0, remove: data.state == -1, add: data.state === 1 }]"
    :open="(openKeys || []).includes(data.name)"
    @click="toggleOpen($event)"
  >
    <summary :class="['treeitem-title', { leaf: !data?.children?.length }]">
      <span class="txttitle">
        <img src="@/assets/folder.png" class="typeimg" v-if="data?.children?.length" />
        <img src="@/assets/file.png" class="typeimg" v-else />
        {{ (data.name || '').split(/\//gi).pop() }}
      </span>
    </summary>

    <TreeItem
      :open-keys="openKeys"
      v-for="item in data.children"
      :key="item.name"
      :compareType="compareType"
      :data="item"
      @toggle="toggleItem"
      @leaf-click="leafClick"
    ></TreeItem>
  </details>
</template>

<script lang="ts" setup>
// 0 modify
export type TreeItemType = {
  name: string;
  state?: number;
  hasModify?: boolean; // 是否添加、修改、编辑
  children?: TreeItemType[];
};

defineOptions({
  name: 'TreeItem',
});

const props = withDefaults(
  defineProps<{
    data: TreeItemType;
    openKeys?: string[];
    compareType: 'origin' | 'target';
  }>(),
  {},
);

const emit = defineEmits<{
  (event: 'toggle', value: TreeItemType): void;
  (event: 'leafClick', value: TreeItemType): void;
}>();

function toggleOpen(evt: Event) {
  evt.preventDefault();
  evt.stopPropagation();
  // 叶子节点不能展开

  if (!props.data?.children?.length) {
    leafClick(props.data);
    return;
  }

  // 如果是新增或则删掉的文件夹  如果当前是隐藏状态 则不能操作   新增则左侧隐藏  删除则右侧隐藏
  if (props.data.state == -1 && props.compareType == 'target') {
    return;
  }
  if (props.data.state == 1 && props.compareType == 'origin') {
    return;
  }

  toggleItem(props.data);
}

function toggleItem(item: TreeItemType) {
  emit('toggle', item);
}

function leafClick(item: TreeItemType) {
  emit('leafClick', item);
}
</script>

<style lang="less" scoped>
.treeitem {
  padding-left: 20px;

  &.modify {
    & > summary.treeitem-title {
      cursor: pointer !important;
      .txttitle {
        color: rgba(19, 113, 255, 1);
      }
    }
  }

  &.remove {
    &.target {
      opacity: 0;
      // visibility: collapse;
    }
    & > summary.treeitem-title {
      .txttitle {
        color: rgba(235, 0, 0, 0.85);
      }
    }
  }

  &.add {
    &.origin {
      opacity: 0;
      // visibility: collapse;
    }
    & > summary.treeitem-title {
      .txttitle {
        color: rgba(0, 194, 43, 1);
      }
    }
  }

  &:last-child {
    & > summary.treeitem-title {
      &.leaf {
        &::after {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  &[open] {
    > summary.treeitem-title {
      &::before {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  /* 更改摘要标记颜色 */
  & > summary.treeitem-title {
    cursor: pointer;
    padding: 5px 0;
    position: relative;
    .txttitle {
      position: relative;
      z-index: 2;
      display: inline-flex;
      align-items: center;
      pointer-events: none;

      .typeimg {
        width: 16px;
        margin-right: 6px;
        position: relative;
        top: 2px;
      }
    }

    &.leaf {
      &::after {
        border-bottom: 0px;
        background: #fff;
      }
    }
    &::after {
      position: absolute;
      z-index: 1;
      width: 10000px;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      content: ' ';
      display: block;
      border-bottom: 1px solid #ccc;
      // pointer-events: none;
    }

    &:hover {
      &::after {
        background: #eee !important;
      }
      &.leaf::after {
        background: red;
      }
    }

    &::before {
      position: absolute;
      z-index: 2;
      width: 0;
      height: 0;
      border-left: 8px solid #999;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      font-size: 0;
      line-height: 0;
      content: ' ';
      display: block;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s;
    }

    &::marker {
      color: transparent;
    }

    &.leaf {
      cursor: unset;
      &::before {
        display: none;
      }
      &::marker {
        color: transparent;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .treeitem {
    padding-left: 10px;

    &-title {
      &::marker {
        margin-left: 0;
      }
    }
  }
}
</style>
