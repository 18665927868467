import { message } from 'ant-design-vue';
import axios, { AxiosPromise, Method, ResponseType } from 'axios';
import { AES_ECB_ENCRYPT } from '@/utils/crypto';
import { Md5 } from 'ts-md5/dist/esm/md5';

declare module 'axios' {
  interface AxiosResponse {
    code: string;
    msg: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
  export function create(config?: AxiosRequestConfig): AxiosInstance;
}

const baseURL = import.meta.env['VITE_BASE_API'] || '';
const axiosInstance = axios.create({
  baseURL,
  timeout: 20000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // 接入单点系统要加
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.params) {
      config.params['timestamp'] = new Date().getTime();
    } else {
      config.params = {
        timestamp: new Date().getTime(),
      };
    }

    let bodyData = '';
    if (config.headers['Content-Type'] == 'application/x-www-form-urlencoded; charset=UTF-8') {
      Object.keys(config.data)
        .sort()
        .forEach((item) => {
          bodyData += `${item}=${config.data[item]}`;
        });
    } else {
      bodyData = config.data && Object.keys(config.data).length ? JSON.stringify(config.data) : '';
    }
    const temp = config.params
      ? Object.assign(JSON.parse(JSON.stringify(config.params)), {
          body: bodyData,
        })
      : { body: bodyData };
    let i = '';
    const arr = Object.keys(temp).sort();
    //拼接params + data
    arr.forEach((item) => {
      i += `${item}${temp[item]}`;
    });

    Object.assign(config.headers, {
      ...config.headers,
      sign: Md5.hashStr(`wJ8V7fKcT2Q${i}wJ8V7fKcT2Q`),
    });

    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  (res) => {
    if (res?.headers?.['location'] && import.meta.env.VITE_SINGLE_SiGN) {
      window.location.href = res.headers['location'];
    }
    if (Number(res.data?.code) === 401) {
      message.warning('登录失效，请重新登录');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('customerId');
    }

    return res.data;
  },
  (error) => {
    if (error.response?.headers?.location && import.meta.env.VITE_SINGLE_SiGN) {
      window.location.href = error.response.headers.location;
    }
    if (error.response?.status === 500) {
      message.error('服务端接口报错');
    }
    if (error.code === 'ERR_CANCELED') {
      return { code: '6003' }; // 取消的请求 不报错的处理
    } else {
      return Promise.reject(error);
    }
  },
);

// 函数重载，根据cancelFlag判断是否返回类型
function request(url: string, method: string, params?: object, cancelFlag?: false): AxiosPromise;
function request(
  url: string,
  method: string,
  params: object | undefined,
  cancelFlag: true,
): {
  requestFn: AxiosPromise;
  controller: AbortController;
};

function request(url: string, method: string, params?: object, cancelFlag?: boolean) {
  const realMethod = method.split('_')[0] as Method;
  const requestParams = {
    url,
    method: realMethod,
    headers: getHeader(method),
    // // 服务器响应的数据类型， 可以是‘arraybuffer','blob', 'documnet','json','text','stream'
    responseType: getResponseType(method),
  };

  if (params) {
    if (/get|GET/.test(method)) {
      Object.assign(requestParams, { params });
    } else if (/post|POST/.test(method)) {
      if (method === 'POST_JSON_AES' || method === 'POST_IMAGE_AES') {
        Object.assign(requestParams, {
          data: {
            bodyData: AES_ECB_ENCRYPT(JSON.stringify(params)),
          },
        });
      } else {
        Object.assign(requestParams, { data: params });
      }
    }
  }

  if (cancelFlag) {
    const controller = new AbortController();
    Object.assign(requestParams, { signal: controller.signal });
    return { requestFn: axiosInstance(requestParams), controller };
  }
  return axiosInstance(requestParams);
}

export function getHeader(method: string) {
  switch (method) {
    case 'POST_FORM':
      return {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      };
    case 'POST_FILE':
      return {
        'Content-Type': 'multipart/form-data',
      };
    default:
      return {
        'Content-Type': 'application/json',
      };
  }
}

export function getResponseType(method: string): ResponseType {
  switch (method) {
    case 'POST_DOWNLOAD':
      return 'blob';
    default:
      return 'json';
  }
}

export default request;
